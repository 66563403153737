class TopSlider {
    private intervalId: number | null = null;
    private sliderImage = document.querySelector<HTMLElement>(".slider-image");

    constructor() {
        document.addEventListener("visibilitychange", () => {
            if (document.visibilityState === "visible") {
                return this.startInterval();
            }

            if (this.intervalId) {
                clearInterval(this.intervalId);
            }
        });

        if (document.visibilityState === "visible") {
            this.startInterval();
        }
    }

    private moveSlider() {
        if (!this.sliderImage) {
            return;
        }

        let activeSlideElement = document.querySelector(".slider-image.active");

        if (!activeSlideElement) {
            this.sliderImage.classList.add("active");
            activeSlideElement = document.querySelector(".slider-image.active");
        }

        const nextSlide =
            this.getNextSlide(activeSlideElement) ?? this.sliderImage;

        nextSlide?.classList.add("active");
        activeSlideElement?.classList.remove("active");
    }

    private getNextSlide(activeElement: Element | null): Element | null {
        let nextSlide = activeElement?.nextElementSibling;

        if (!nextSlide?.classList.contains("slider-image")) {
            nextSlide = null;
        }

        return nextSlide;
    }

    private startInterval() {
        this.intervalId = setInterval(() => this.moveSlider(), 6_000);
    }
}

new TopSlider();